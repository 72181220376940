<template>
  <div>
    <ek-table :columns="cols" :items="jobs" @details="navigate" @delete-selected="removeJobs">
      <template slot="items.senderId" slot-scope="{ value }">
        {{ getActivityOf(value).name }}
      </template>
      <template slot="items.city" slot-scope="{ props }">
        {{ getActivityOf(props.row.senderId).location }}
      </template>
      <template slot="items.commercialName" slot-scope="{ props }">
        {{ getActivityOf(props.row.senderId).commercialName }}
      </template>

      <template slot="items.isAvailable" slot-scope="{ value }">
        <b-badge
          class="rounded-pill p-50"
          variant="success"
          :class="{ unavailable: !value }"
          >{{ value ? "متاح" : "انتهى التقديم" }}</b-badge
        >
      </template>

      <template slot="items.deadLineDate" slot-scope="{ value }">
        {{ value ? new Date(value).toLocaleDateString() : '-' }}
      </template>
      <template slot="items.jobStatus" slot-scope="{ value }">
        {{ jobTypes.find((el) => el.id == value) || "غير محدد" }}
      </template>
    </ek-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      cols: [
        {
          label: "اسم الفعالية",
          field: "senderId",
        },
        {
          label: "النشاط التجاري",
          field: "commercialName",
        },
        {
          label: "المدينة",
          field: "city",
        },
        {
          label: "المسمى الوظيفي",
          field: "jobTitle",
        },
        {
          label: "اخر موعد للتقديم",
          field: "deadLineDate",
        },

        {
          label: "حالة الوظيفة",
          field: "isAvailable",
        },

        {
          label: "تفاصيل",
          field: "details",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      jobs: ({ jobs }) => jobs.jobs,
      jobTypes: ({ jobs }) => jobs.jobTypes,
      activitiesList: ({ activities }) => activities.activities,
    }),
  },
  methods: {
    ...mapActions(["getJobs", "deleteJobs"]),
    navigate({ row }) {
      this.$router.push(`jobs/${row.id}`);
    },
    getActivityOf(value) {
      return this.activitiesList.find((el) => el.id == value);
    },
    removeJobs(e) {
        this.deleteJobs(e)
    }
  },
  created() {
    this.getJobs();
  },
};
</script>

<style>
.unavailable {
  color: #ff9f43 !important;
  background-color: #ff9e4328 !important;
}
</style>
