var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ek-table',{attrs:{"columns":_vm.cols,"items":_vm.jobs},on:{"details":_vm.navigate,"delete-selected":_vm.removeJobs},scopedSlots:_vm._u([{key:"items.senderId",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getActivityOf(value).name)+" ")]}},{key:"items.city",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.getActivityOf(props.row.senderId).location)+" ")]}},{key:"items.commercialName",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.getActivityOf(props.row.senderId).commercialName)+" ")]}},{key:"items.isAvailable",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{staticClass:"rounded-pill p-50",class:{ unavailable: !value },attrs:{"variant":"success"}},[_vm._v(_vm._s(value ? "متاح" : "انتهى التقديم"))])]}},{key:"items.deadLineDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? new Date(value).toLocaleDateString() : '-')+" ")]}},{key:"items.jobStatus",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.jobTypes.find(function (el) { return el.id == value; }) || "غير محدد")+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }